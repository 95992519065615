import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from './AuthRouteProvider'
import { PMSsubMenuConfig } from '../config/constants'
import { GetCurrentUser } from '../services/user'

/**
 * Route guard component that restricts access to only PMS-related routes
 * when hotelPmsIntergationType is 'Only Pms'
 */
const PMSOnlyRouteGuard = ({ children }) => {
  const { hotelInfo, isAppLoaded } = useContext(AuthContext)

  // Log PMS integration type for debugging
  useEffect(() => {
    if (hotelInfo) {
      console.log('PMS Integration Type:', hotelInfo.pmsIntergationType)
      console.log('PMS Type:', hotelInfo.pmsType)
    }
  }, [hotelInfo])

  // Get the current path
  const currentPath = window.location.pathname

  // Public routes that should always be accessible
  const publicRoutes = [
    '/',
    '/SignIn',
    '/ForgotPassword',
    '/ResetPassword',
    '/OtpVerification',
    '/LinkExpired',
    '/maintenance',
    '/password-change-success',
    '/dept-confirm',
    '/dept-verify',
  ]

  // Check if the current path is a public route or starts with a public route
  const isPublicRoute = publicRoutes.some(
    route => currentPath === route || currentPath.startsWith(`${route}/`)
  )

  // If it's a public route, allow access
  if (isPublicRoute) {
    return children
  }

  // If user is not logged in or app is not loaded yet, allow the router to handle the redirect to login
  if (!GetCurrentUser() || !isAppLoaded || !hotelInfo) {
    return children
  }

  // Check if the hotel is in 'Only Pms' mode
  const isOnlyPmsMode =
    hotelInfo?.pmsIntergationType === 'Only Pms' &&
    hotelInfo?.pmsType !== 'None'

  if (isOnlyPmsMode) {
    // Get all PMS-related paths
    const pmsRoutes = [...PMSsubMenuConfig.map(item => item.linkTo), '/PmsConfiguration']

    // Check if the current path is a PMS route
    const isPmsRoute = pmsRoutes.some(route => currentPath === route)

    // If not a PMS route, redirect to the first PMS route
    if (!isPmsRoute && pmsRoutes.length > 0) {
      console.log(
        `Redirecting from ${currentPath} to ${pmsRoutes[0]} because hotel is in 'Only Pms' mode`
      )
      return <Redirect to={pmsRoutes[0]} />
    }
  }

  // If not in 'Only Pms' mode or if the current path is a PMS route, render children
  return children
}

export default PMSOnlyRouteGuard
