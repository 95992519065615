import React, { useState, useEffect } from 'react'
import { Input, Radio, Checkbox } from 'antd'

const CronScheduler = ({ value, onChange, showCronExpression = true }) => {
  // State for scheduler
  const [cronType, setCronType] = useState('minutes')
  const [minutesValue, setMinutesValue] = useState(10)
  const [hoursValue, setHoursValue] = useState(1)
  const [scheduleTime, setScheduleTime] = useState('08:00')
  const [selectedWeekdays, setSelectedWeekdays] = useState(['1'])
  const [selectedMonthDays, setSelectedMonthDays] = useState(['1'])
  const [cronExpression, setCronExpression] = useState('')

  // Initialize with any existing value
  useEffect(() => {
    if (value) {
      // If we already have structured data
      if (typeof value === 'object') {
        setCronType(value.cronType || 'minutes')
        setMinutesValue(value.minutesValue || 10)
        setHoursValue(value.hoursValue || 1)
        setScheduleTime(value.scheduleTime || '08:00')
        setSelectedWeekdays(value.selectedWeekdays || ['1'])
        setSelectedMonthDays(value.selectedMonthDays || ['1'])
        setCronExpression(value.cronExpression || '')
      }
      // If we have just a cron string
      else if (typeof value === 'string') {
        setCronExpression(value)
        parseCronExpression(value)
      }
    }
  }, [])

  // Parse a cron expression into UI state
  const parseCronExpression = cronExp => {
    // Handle minutes interval (*/X * * * *)
    if (cronExp.match(/^\*\/(\d+)\s+\*\s+\*\s+\*\s+\*$/)) {
      const minutes = cronExp.match(/^\*\/(\d+)\s+\*\s+\*\s+\*\s+\*/)[1]
      setCronType('minutes')
      setMinutesValue(parseInt(minutes))
    }
    // Handle hours interval (0 */X * * *)
    else if (cronExp.match(/^0\s+\*\/(\d+)\s+\*\s+\*\s+\*$/)) {
      const hours = cronExp.match(/^0\s+\*\/(\d+)\s+\*\s+\*\s+\*/)[1]
      setCronType('hours')
      setHoursValue(parseInt(hours))
    }
    // Handle daily (M H * * *)
    else if (cronExp.match(/^(\d+)\s+(\d+)\s+\*\s+\*\s+\*$/)) {
      const matches = cronExp.match(/^(\d+)\s+(\d+)\s+\*\s+\*\s+\*$/)
      const minute = parseInt(matches[1])
      let hour = parseInt(matches[2])

      setCronType('daily')
      setScheduleTime(
        `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
      )
    }
    // Handle weekly (M H * * D,D,D)
    else if (cronExp.match(/^(\d+)\s+(\d+)\s+\*\s+\*\s+([0-6](,[0-6])*)$/)) {
      const matches = cronExp.match(
        /^(\d+)\s+(\d+)\s+\*\s+\*\s+([0-6](,[0-6])*)$/
      )
      const minute = parseInt(matches[1])
      let hour = parseInt(matches[2])
      const days = matches[3].split(',')

      setCronType('weekly')
      setScheduleTime(
        `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
      )
      setSelectedWeekdays(days)
    }
    // Handle monthly (M H D * *)
    else if (
      cronExp.match(/^(\d+)\s+(\d+)\s+([0-9LW](,[0-9LW])*)\s+\*\s+\*$/)
    ) {
      const matches = cronExp.match(
        /^(\d+)\s+(\d+)\s+([0-9LW](,[0-9LW])*)\s+\*\s+\*$/
      )
      const minute = parseInt(matches[1])
      let hour = parseInt(matches[2])
      const monthDays = matches[3].split(',')

      setCronType('monthly')
      setScheduleTime(
        `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
      )
      setSelectedMonthDays(monthDays)
    }
  }

  // Generate human-readable cron description
  const getCronDescription = () => {
    switch (cronType) {
      case 'minutes':
        return `Reservation will refresh every ${minutesValue} minute${
          minutesValue > 1 ? 's' : ''
        }`
      case 'hours':
        return `Reservation will refresh every ${hoursValue} hour${
          hoursValue > 1 ? 's' : ''
        }`
      case 'daily':
        return `Reservation will refresh daily at ${scheduleTime}`
      case 'weekly':
        const days = selectedWeekdays
          .map(d => {
            const dayNames = [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ]
            return dayNames[parseInt(d)]
          })
          .join(', ')
        return `Reservation will refresh on ${days} at ${scheduleTime}`
      case 'monthly':
        const dayStr = selectedMonthDays.join(', ')
        return `Reservation will refresh on day${
          selectedMonthDays.length > 1 ? 's' : ''
        } ${dayStr} of each month at ${scheduleTime}`
      default:
        return 'Please select a schedule option'
    }
  }

  // Convert UI settings to cron expression
  const generateCronExpression = () => {
    const [hour, minute] = scheduleTime.split(':').map(Number)
    let expression = ''

    switch (cronType) {
      case 'minutes':
        expression = `*/${minutesValue} * * * *`
        break
      case 'hours':
        expression = `0 */${hoursValue} * * *`
        break
      case 'daily':
        expression = `${minute} ${hour} * * *`
        break
      case 'weekly':
        expression = `${minute} ${hour} * * ${selectedWeekdays.join(',')}`
        break
      case 'monthly':
        expression = `${minute} ${hour} ${selectedMonthDays.join(',')} * *`
        break
      default:
        expression = '*/10 * * * *' // Default fallback
    }

    return expression
  }

  // Update values and notify parent when changes occur
  useEffect(() => {
    const newCronExpression = generateCronExpression()
    setCronExpression(newCronExpression)

    if (onChange) {
      onChange({
        cronType,
        minutesValue,
        hoursValue,
        scheduleTime,
        selectedWeekdays,
        selectedMonthDays,
        cronExpression: newCronExpression,
        cronDescription: getCronDescription(),
      })
    }
  }, [
    cronType,
    minutesValue,
    hoursValue,
    scheduleTime,
    selectedWeekdays,
    selectedMonthDays,
  ])

  // Generate options for monthly days
  const generateMonthDays = () => {
    const days = []
    for (let i = 1; i <= 31; i++) {
      days.push(i.toString())
    }
    return days
  }

  // Handle checkbox change for weekdays
  const handleWeekdayChange = day => {
    const currentSelected = [...selectedWeekdays]
    const dayIndex = currentSelected.indexOf(day)

    if (dayIndex === -1) {
      currentSelected.push(day)
    } else {
      currentSelected.splice(dayIndex, 1)
    }

    // Ensure at least one day is selected
    if (currentSelected.length > 0) {
      setSelectedWeekdays(currentSelected)
    }
  }

  // Handle checkbox change for month days
  const handleMonthDayChange = day => {
    const currentSelected = [...selectedMonthDays]
    const dayIndex = currentSelected.indexOf(day)

    if (dayIndex === -1) {
      currentSelected.push(day)
    } else {
      currentSelected.splice(dayIndex, 1)
    }

    // Ensure at least one day is selected
    if (currentSelected.length > 0) {
      setSelectedMonthDays(currentSelected)
    }
  }

  return (
    <div className='cron-scheduler'>
      <Radio.Group value={cronType} onChange={e => setCronType(e.target.value)}>
        <div className='schedule-option' style={{ marginBottom: '10px' }}>
          <Radio value='minutes'>Every X Minutes</Radio>
          {cronType === 'minutes' && (
            <div
              className='option-inputs'
              style={{ marginLeft: '25px', marginTop: '5px' }}
            >
              <Input
                type='number'
                min={1}
                max={59}
                value={minutesValue}
                onChange={e => setMinutesValue(parseInt(e.target.value) || 1)}
                style={{ width: '80px' }}
              />
            </div>
          )}
        </div>

        <div className='schedule-option' style={{ marginBottom: '10px' }}>
          <Radio value='hours'>Every X Hours</Radio>
          {cronType === 'hours' && (
            <div
              className='option-inputs'
              style={{ marginLeft: '25px', marginTop: '5px' }}
            >
              <Input
                type='number'
                min={1}
                max={23}
                value={hoursValue}
                onChange={e => setHoursValue(parseInt(e.target.value) || 1)}
                style={{ width: '80px' }}
              />
            </div>
          )}
        </div>

        <div className='schedule-option' style={{ marginBottom: '10px' }}>
          <Radio value='daily'>Daily</Radio>
          {cronType === 'daily' && (
            <div
              className='option-inputs'
              style={{ marginLeft: '25px', marginTop: '5px' }}
            >
              <span style={{ marginRight: '10px' }}>At</span>
              <Input
                type='time'
                value={scheduleTime}
                onChange={e => setScheduleTime(e.target.value)}
                style={{ width: '120px' }}
              />
            </div>
          )}
        </div>

        <div className='schedule-option' style={{ marginBottom: '10px' }}>
          <Radio value='weekly'>Weekly</Radio>
          {cronType === 'weekly' && (
            <div
              className='option-inputs'
              style={{ marginLeft: '25px', marginTop: '5px' }}
            >
              <div style={{ marginBottom: '10px' }}>
                <span style={{ marginRight: '10px' }}>At</span>
                <Input
                  type='time'
                  value={scheduleTime}
                  onChange={e => setScheduleTime(e.target.value)}
                  style={{ width: '120px' }}
                />
              </div>
              <div style={{ marginBottom: '5px' }}>On days:</div>
              <div>
                <Checkbox
                  checked={selectedWeekdays.includes('0')}
                  onChange={() => handleWeekdayChange('0')}
                  style={{ marginRight: '10px' }}
                >
                  Sunday
                </Checkbox>
                <Checkbox
                  checked={selectedWeekdays.includes('1')}
                  onChange={() => handleWeekdayChange('1')}
                  style={{ marginRight: '10px' }}
                >
                  Monday
                </Checkbox>
                <Checkbox
                  checked={selectedWeekdays.includes('2')}
                  onChange={() => handleWeekdayChange('2')}
                  style={{ marginRight: '10px' }}
                >
                  Tuesday
                </Checkbox>
                <Checkbox
                  checked={selectedWeekdays.includes('3')}
                  onChange={() => handleWeekdayChange('3')}
                  style={{ marginRight: '10px' }}
                >
                  Wednesday
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={selectedWeekdays.includes('4')}
                  onChange={() => handleWeekdayChange('4')}
                  style={{ marginRight: '10px' }}
                >
                  Thursday
                </Checkbox>
                <Checkbox
                  checked={selectedWeekdays.includes('5')}
                  onChange={() => handleWeekdayChange('5')}
                  style={{ marginRight: '10px' }}
                >
                  Friday
                </Checkbox>
                <Checkbox
                  checked={selectedWeekdays.includes('6')}
                  onChange={() => handleWeekdayChange('6')}
                >
                  Saturday
                </Checkbox>
              </div>
            </div>
          )}
        </div>

        <div className='schedule-option'>
          <Radio value='monthly'>Monthly</Radio>
          {cronType === 'monthly' && (
            <div
              className='option-inputs'
              style={{ marginLeft: '25px', marginTop: '5px' }}
            >
              <div style={{ marginBottom: '10px' }}>
                <span style={{ marginRight: '10px' }}>At</span>
                <Input
                  type='time'
                  value={scheduleTime}
                  onChange={e => setScheduleTime(e.target.value)}
                  style={{ width: '120px' }}
                />
              </div>
              <div style={{ marginBottom: '5px' }}>On day(s):</div>
              <div
                style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '500px' }}
              >
                {generateMonthDays().map(day => (
                  <Checkbox
                    key={day}
                    checked={selectedMonthDays.includes(day)}
                    onChange={() => handleMonthDayChange(day)}
                    style={{ width: '60px', marginBottom: '5px' }}
                  >
                    {day}
                  </Checkbox>
                ))}
              </div>
            </div>
          )}
        </div>
      </Radio.Group>

      <div
        className='schedule-description'
        style={{
          marginTop: '15px',
          padding: '10px',
          background: '#f0f2f5',
          borderRadius: '4px',
          fontWeight: 'bold',
        }}
      >
        {getCronDescription()}
      </div>

      {showCronExpression && (
        <div
          className='cron-expression'
          style={{
            marginTop: '10px',
            fontSize: '12px',
            color: '#666',
          }}
        >
          {/* Cron expression: {cronExpression} */}
        </div>
      )}
    </div>
  )
}

export default CronScheduler
