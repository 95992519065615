import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import {
  Table,
  Button,
  message,
  Modal,
  Input,
  DatePicker,
  Select,
  Space,
} from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import {
  AddGuestListener,
  AcceptRejectCheckIn,
} from '../../../../services/guest'
import PageNameCard from '../../../Common/PageNameCard/PageNameCard'
import { GetCurrentUser } from '../../../../services/user'
import {
  PaginationOptions,
  secondsToShowAlert,
  APIs,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AdminRequest } from '../../../../services/notification'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import { getCommonColumns, getImage } from '../../../../config/utils'
import {
  UpdateGuestRoomNumber,
  sendEmailByEmailId,
  preCheckinRequestCollectionListener,
  unsubscribePreCheckinRequests,
} from '../../../../services/pms'
import Axios from '../../../../utility/axiosHelper'
import axios from 'axios'
import { auth, db, firebase } from '../../../../config/firebase'
import moment from 'moment'

const PMSAssignRooms = () => {
  const { hotelId, userInfo } = useContext(AuthContext)
  const dispatch = useDispatch()
  const [translateTextI18N] = useCustomI18NTranslatorHook()

  // State management
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRoomNumber, setSelectedRoomNumber] = useState('')
  const [selectedGuest, setSelectedGuest] = useState(null)
  const preCheckinRequestsData = useSelector(state => state.preCheckinRequests)
  const [isIdImageModalVisible, setIsIdImageModalVisible] = useState(false)
  const [selectedIdImage, setSelectedIdImage] = useState(null)
  const { hotelInfo } = useSelector(state => state)
  const [isImageLoading, setIsImageLoading] = useState(true)
  // Generate unique values for filters
  const [bookingReferences, setBookingReferences] = useState([])
  const [guestNames, setGuestNames] = useState([])

  // Filter states
  const [filters, setFilters] = useState({
    bookingReference: '',
    guestName: '',
    arrivalDate: null,
  })

  const hotelPmsIntergationType = hotelInfo?.pmsIntergationType

  useEffect(() => {
    if (preCheckinRequestsData && preCheckinRequestsData.length > 0) {
      const uniqueBookingReferences = [
        ...new Set(preCheckinRequestsData.map(item => item.bookingReferance)),
      ]
        .filter(Boolean)
        .map(ref => ({
          label: ref,
          value: ref,
        }))

      const uniqueGuestNames = [
        ...new Set(preCheckinRequestsData.map(item => item.guestName)),
      ]
        .filter(Boolean)
        .map(name => ({
          label: name,
          value: name,
        }))

      setBookingReferences(uniqueBookingReferences)
      setGuestNames(uniqueGuestNames)
    }
  }, [preCheckinRequestsData])

  // Filter data based on filter values
  const filteredData = useMemo(() => {
    if (!preCheckinRequestsData) return []

    return preCheckinRequestsData
      .filter(item => {
        const matchesSource = item.source === 'pms'
        const matchesStatus =
          item.status === 'Accepted' || item.status === 'Check In'
        const matchesBookingRef =
          !filters.bookingReference ||
          (item.bookingReferance &&
            item.bookingReferance.includes(filters.bookingReference))
        const matchesGuestName =
          !filters.guestName ||
          (item.guestName && item.guestName.includes(filters.guestName))
        const matchesArrivalDate =
          !filters.arrivalDate ||
          (item.checkin &&
            moment(item.checkin).isSame(filters.arrivalDate, 'day'))

        return (
          matchesSource &&
          matchesStatus &&
          matchesBookingRef &&
          matchesGuestName &&
          matchesArrivalDate
        )
      })
      .map((item, index) => ({ ...item, index }))
  }, [preCheckinRequestsData, filters])

  // Effects
  useEffect(() => {
    const pmsType = hotelInfo?.pmsType
    AddGuestListener(hotelId, dispatch)
    preCheckinRequestCollectionListener(hotelId, pmsType, dispatch)
    return () => {
      unsubscribePreCheckinRequests(hotelId)
    }
  }, [dispatch, hotelId, hotelInfo])

  // Utility functions
  const setTemporaryMessage = (setMessageFunc, messageText) => {
    setMessageFunc(translateTextI18N(messageText))
    setTimeout(() => setMessageFunc(''), secondsToShowAlert)
  }

  const handleRoomAssignment = useCallback(async () => {
    try {
      if (!selectedGuest) {
        return { success: false, message: 'No guest selected' }
      }

      setShowLoader(true)

      // Check if the room is already occupied
      const occupiedRooms = preCheckinRequestsData.filter(
        guest =>
          guest.roomNumberLc === selectedRoomNumber?.toLowerCase() &&
          guest.id !== selectedGuest.id && // Exclude current guest
          (guest.status === 'Accepted' || guest.status === 'Check In') // Check for active guests
      )

      if (occupiedRooms.length > 0) {
        return { success: false, message: 'Room number already occupied' }
      }

      const { success, message: responseMessage } = await AcceptRejectCheckIn(
        hotelId,
        selectedGuest.id,
        true,
        selectedRoomNumber,
        preCheckinRequestsData
      )

      if (success) {
        const userId = GetCurrentUser().uid
        // AdminRequest has been commented out as requested
        setTemporaryMessage(setSuccessMessage, 'Room assigned successfully')
        return { success: true }
      } else {
        setTemporaryMessage(setErrorMessage, responseMessage)
        return { success: false, message: responseMessage }
      }
    } catch (error) {
      message.error('Something went wrong! Please try again!')
      return { success: false, message: 'Error during room assignment' }
    } finally {
      setShowLoader(false)
      setIsModalVisible(false)
    }
  }, [hotelId, selectedGuest, selectedRoomNumber, preCheckinRequestsData])

  const handleUpdateRoomNumber = useCallback(async () => {
    try {
      setShowLoader(true)
      if (!selectedRoomNumber || !selectedGuest) {
        message.error('Room number and guest information are required')
        return
      }

      // Check if the room is already assigned to this guest
      if (selectedGuest.roomNumber && selectedGuest.roomNumber.trim() !== '') {
        message.warning('The room is already assigned to this guest')
        setShowLoader(false)
        setIsModalVisible(false)
        return
      }

      // First try to assign the room
      const result = await handleRoomAssignment()
      if (!result?.success) {
        message.error(result?.message || 'Failed to assign room')
        setShowLoader(false)
        return
      }

      await UpdateGuestRoomNumber(selectedGuest.guestId, selectedRoomNumber)

      // Only proceed with email if everything is successful
      try {
        const response = await axios.get(`${APIs.GUEST_WIFI_ONBOARDING}`, {
          params: {
            operation: 'generateToken',
            property: hotelInfo?.domain,
            guestId: selectedGuest?.guestId,
            hotelId: hotelInfo?.hotelId,
          },
        })

        const data = response.data
        if (!data.success) {
          throw new Error('Failed to generate custom token for guest')
        }

        // const guestAppLink = data.clientURL
        const token = data?.token
        // console.log('guestAppLink', guestAppLink)

        // Construct the link with the custom token
        const guestAppLink = `https://dev-guest.inplass.online/bookingreference/?token=${token}`

        const emailVariables = {
          '%firstName%': selectedGuest?.name || '',
          '%lastName%': selectedGuest?.surName || '',
          '%link%': guestAppLink,
          '%roomNumber%': selectedRoomNumber,
          '%bookingReferance%': selectedGuest.bookingReferance,
        }
        const emailResponse = await sendEmailByEmailId(
          selectedGuest.email,
          emailVariables,
          'assignRoom',
          hotelId
        )
        if (emailResponse.success) {
          // Changed to use setTemporaryMessage with setSuccessMessage instead of message.success
          setTemporaryMessage(setSuccessMessage, 'Email sent successfully')
        } else {
          message.warning('Room assigned but failed to send email')
        }
      } catch (emailError) {
        console.error('Error sending email:', emailError)
        message.warning('Room assigned but failed to send email')
      }

      setShowLoader(false)
      setIsModalVisible(false)
    } catch (error) {
      console.error('Error in room assignment:', error)
      message.error('Failed to update room number')
      setShowLoader(false)
    }
  }, [selectedGuest, selectedRoomNumber, hotelId])

  const handleShowIdImage = imageUrl => {
    setIsImageLoading(true)
    setSelectedIdImage(imageUrl)
    setIsIdImageModalVisible(true)
  }

  // Function to open modal with room assignment
  const openRoomAssignmentModal = guest => {
    // Check if the room is already assigned
    if (guest.roomNumber && guest.roomNumber.trim() !== '') {
      message.warning('This Guest already assigned with a room')
      return
    }

    setIsModalVisible(true)
    setSelectedRoomNumber(guest.roomNumber || '')
    setSelectedGuest(guest)
  }

  const resetFilters = () => {
    setFilters({
      bookingReference: '',
      guestName: '',
      arrivalDate: null,
    })
  }

  // Table configuration
  const { bookingReferanceCol, roomNumberCol, submittedTimeCol } =
    getCommonColumns({ translateTextI18N })

  const columns = [
    {
      title: 'Sl.No',
      dataIndex: 'index',
      width: 100,
      render: (_, __, index) => index + 1,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      width: 170,
    },
    {
      title: translateTextI18N('Room Number'),
      dataIndex: 'roomNumber',
      width: 100,
    },
    {
      ...bookingReferanceCol,
      width: 130,
    },
    // {
    //   title: translateTextI18N('Id Number'),
    //   dataIndex: 'verificationIdNumber',
    //   width: 100,
    // },
    {
      title: translateTextI18N('ID Verification'),
      dataIndex: 'identification',
      width: 120,
      render: (_, row) => {
        return row.identification?.url ? (
          <Button
            type='primary'
            onClick={() => handleShowIdImage(row.identification.url)}
          >
            View ID
          </Button>
        ) : (
          <span>No ID uploaded</span>
        )
      },
    },
    {
      title: translateTextI18N('Check-In Date'),
      dataIndex: 'checkin',
      width: 100,
    },
    {
      title: translateTextI18N('Check-In Time'),
      dataIndex: 'arrival',
      width: 100,
    },
    {
      title: translateTextI18N('Accompanying Guest'),
      dataIndex: 'additionalGuestName',
      width: 170,
    },
    submittedTimeCol,
    {
      title: translateTextI18N('Action'),
      width: 120,
      render: row => (
        <Button
          className='statusBtn completedBtn ml-2'
          onClick={() => openRoomAssignmentModal(row)}
        >
          {translateTextI18N('Assign Room')}
        </Button>
      ),
    },
  ]

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNameCard
                title='Assign Room'
                breadcrumb={
                  hotelPmsIntergationType === 'Only Pms'
                    ? ['PMS', 'Assign Room']
                    : ['Department Admin', 'Front Desk', 'PMS', 'Assign Room']
                }
              />
            </div>

            {/* Filter Section */}
            <div className='col-12'>
              <div className='tablefilter-wrp'>
                <div className='row mb-3'>
                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <div className='row mb-3'>
                        <div className='col-6 col-md-4 col-lg'>
                          <Select
                            id='bookingReferenceFilter'
                            options={bookingReferences}
                            placeholder='Booking Reference'
                            value={filters.bookingReference || undefined}
                            onChange={value =>
                              setFilters({
                                ...filters,
                                bookingReference: value,
                              })
                            }
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>

                        <div className='col-6 col-md-4 col-lg'>
                          <Select
                            id='guestNameFilter'
                            options={guestNames}
                            placeholder='Guest Name'
                            value={filters.guestName || undefined}
                            onChange={value =>
                              setFilters({ ...filters, guestName: value })
                            }
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>

                        <div className='col-6 col-md-4 col-lg'>
                          <DatePicker
                            format='DD-MMM-YYYY'
                            placeholder='Arrival Date'
                            value={filters.arrivalDate}
                            onChange={date =>
                              setFilters({ ...filters, arrivalDate: date })
                            }
                            style={{ width: '100%' }}
                            allowClear
                          />
                        </div>

                        <div className='col-6 col-md-auto '>
                          <div className='cmnSelect-form'>
                            <Button
                              id='realIncoReset'
                              type='primary'
                              className='adduserbtn'
                              onClick={resetFilters}
                            >
                              <img
                                src={getImage('images/clearicon.svg')}
                                alt=''
                              ></img>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={filteredData}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      loading={showLoader}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title='Assign Room Number'
        visible={isModalVisible}
        onOk={handleUpdateRoomNumber}
        onCancel={() => setIsModalVisible(false)}
      >
        <Input
          value={selectedRoomNumber}
          onChange={e => setSelectedRoomNumber(e.target.value)}
          placeholder='Enter room number'
          className='w-full'
        />
      </Modal>
      <Modal
        title='ID Verification'
        visible={isIdImageModalVisible}
        onCancel={() => {
          setIsIdImageModalVisible(false)
          setSelectedIdImage(null)
          setIsImageLoading(true)
        }}
        footer={null}
        width={800}
      >
        {selectedIdImage && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isImageLoading && (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                Loading...
              </div>
            )}
            <img
              src={selectedIdImage}
              alt='ID Verification'
              style={{
                maxWidth: '100%',
                maxHeight: '80vh',
                display: isImageLoading ? 'none' : 'block',
              }}
              onLoad={() => setIsImageLoading(false)}
            />
          </div>
        )}
      </Modal>
    </>
  )
}

export default PMSAssignRooms
