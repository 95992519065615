import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Table, Button, Space, DatePicker, Select } from 'antd'
import {
  ReservationCollectionListener,
  callOperaReservationCollectionSaveListenerApi,
  callOperaCloudReservationCollectionSaveListenerApi,
  unsubscribeReservations,
  sendEmailByEmailId,
} from '../../../../services/pms'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import {
  PaginationOptions,
  pendingLable,
  emailSentLable,
} from '../../../../config/constants'
import {
  getCommonColumns,
  sanitiseImages,
  getImage,
} from '../../../../config/utils'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'
import crypto from 'crypto-js'
import { db } from '../../../../config/firebase'
import { Collections } from '../../../../config/constants'
import moment from 'moment'

const generateEmailVariables = reservation => {
  const queryParams = `hotel=${encodeURIComponent(
    'our-hotel-resort-spa-dei'
  )}&uniqueId=${encodeURIComponent(reservation?.UniqueID)}`
  const encryptedParams = crypto.AES.encrypt(
    queryParams,
    'pms-precheckin-key'
  ).toString()
  return {
    '%name%': reservation?.guestName,
    '%link%': `https://dev-guest.inplass.online/prechecking?${encryptedParams}`,
    '%checkin%': `${reservation?.checkinDate}`,
    '%logo%': `gs://inplass-dev-new.appspot.com/profileImages/lin5qqpa.jpg`,
  }
}

const getEmailButtonProps = emailAction => {
  console.log('sdsdf', emailAction)
  switch (emailAction) {
    case emailSentLable:
      return { text: 'Sent', disabled: true, showButton: true }
    case pendingLable:
      return { text: 'Send', disabled: false, showButton: true }
    default:
      return { text: 'Send', disabled: false, showButton: false }
  }
}

const PMSreserve = () => {
  const { hotelId, hotelInfo } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const reservations = useSelector(state => state.reservations)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState(null)
  const [email, setEmail] = useState('')
  const [variables, setVariables] = useState('')
  const [emailTemp, setEmailTemp] = useState('')
  const [processedReservations] = useState(new Set())
  const dispatch = useDispatch()
  const [emailTimePeriod, setEmailTimePeriod] = useState(null)

  const hotelPmsIntergationType = hotelInfo?.pmsIntergationType

  // State for filter values
  const [filters, setFilters] = useState({
    reservationId: '',
    bookingReference: '',
    guestName: '',
    arrivalDate: null,
  })

  // State for unique reservation IDs, booking references, and guest names
  const [reservationIds, setReservationIds] = useState([])
  const [bookingReferences, setBookingReferences] = useState([])
  const [guestNames, setGuestNames] = useState([])

  // Date format for DatePicker
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

  // Fetch unique reservation IDs, booking references, and guest names
  useEffect(() => {
    const uniqueReservationIds = [
      ...new Set(reservations.map(res => res.UniqueID)),
    ].map(id => ({ label: id, value: id }))

    const uniqueBookingReferences = [
      ...new Set(reservations.map(res => res.bookingRefrence)),
    ].map(ref => ({ label: ref, value: ref }))

    const uniqueGuestNames = [
      ...new Set(reservations.map(res => res.guestName)),
    ].map(name => ({ label: name, value: name }))

    setReservationIds(uniqueReservationIds)
    setBookingReferences(uniqueBookingReferences)
    setGuestNames(uniqueGuestNames)
  }, [reservations])

  // Filter reservations based on filter values
  const filteredReservations = reservations.filter(reservation => {
    const arrivalDateMatch = filters.arrivalDate
      ? moment(reservation.checkinDate).isSame(filters.arrivalDate, 'day')
      : true

    return (
      (!filters.reservationId ||
        reservation.UniqueID.includes(filters.reservationId)) &&
      (!filters.bookingReference ||
        reservation.bookingRefrence.includes(filters.bookingReference)) &&
      (!filters.guestName ||
        reservation.guestName.includes(filters.guestName)) &&
      arrivalDateMatch
    )
  })

  // Reset filters
  const resetFilter = () => {
    setFilters({
      reservationId: '',
      bookingReference: '',
      guestName: '',
      arrivalDate: null,
    })
  }

  const handleArrivalDateChange = date => {
    setFilters({ ...filters, arrivalDate: date })
  }

  const shouldSendEmail = useCallback((arrivalDate, emailPeriod) => {
    if (!arrivalDate || !emailPeriod) {
      console.log('Missing required data:', { arrivalDate, emailPeriod })
      return false
    }

    const arrival = moment(arrivalDate)
    const now = moment()
    // If it's arrival day setting
    if (emailPeriod.days === 0) {
      const isSameDay = arrival.isSame(now, 'day')
      return isSameDay
    }
    // Calculate when email should be sent
    const emailSendDate = arrival.clone().subtract(emailPeriod.days, 'days')
    const shouldSend = now.isSame(emailSendDate, 'day')

    return shouldSend
  }, [])

  const sendEmail = useCallback(
    async reservation => {
      if (
        processedReservations.has(reservation.UniqueID) &&
        !selectedReservation
      ) {
        console.log('Email already processed for:', reservation.UniqueID)
        return
      }

      // Check if we should send email based on arrival date and configured time period
      if (!shouldSendEmail(reservation.checkinDate, emailTimePeriod)) {
        return
      }

      setShowLoader(true)
      try {
        const variables = generateEmailVariables(reservation)
        const emailTemp = 'pre-checkin'
        const res = await sendEmailByEmailId(
          reservation.email,
          variables,
          emailTemp,
          hotelId
        )
        if (res.success) {
          console.log(`Email sent successfully to ${reservation.email}`)
          setSuccessMessage(res.message)
          if (!selectedReservation) {
            processedReservations.add(reservation.UniqueID)
          }
        } else {
          console.error(`Failed to send email to ${reservation.email}`)
          setErrorMessage(res.message)
        }
      } catch (error) {
        console.error('Error sending email:', error)
        setErrorMessage('Failed to send email')
      } finally {
        setShowLoader(false)
        setSelectedReservation(null)
      }
    },
    [
      hotelId,
      processedReservations,
      selectedReservation,
      emailTimePeriod,
      shouldSendEmail,
    ]
  )

  const handleResendEmail = reservation => {
    setSelectedReservation(reservation)
    setShowConfirmationDialog(true)
    setEmail(reservation.email)
    setVariables(generateEmailVariables(reservation))
    setEmailTemp('pre-checkin')
  }

  useEffect(() => {
    let isMounted = true
    const fetchReservations = async () => {
      if (!hotelId || !hotelInfo?.pmsType) return

      setShowLoader(true)
      try {
        let pmsHotelId = hotelInfo?.pmsHotelId
        const pmsType = hotelInfo?.pmsType
        if (pmsType === 'OPERA CLOUD') {
          const pmsHost = hotelInfo?.operaCloudHostName
          await callOperaCloudReservationCollectionSaveListenerApi(
            pmsHotelId,
            pmsHost
          )
        } else if (pmsType === 'OPERA OWS') {
          await callOperaReservationCollectionSaveListenerApi(hotelInfo)
        }

        if (isMounted) {
          await ReservationCollectionListener(pmsHotelId, pmsType, dispatch)
        }
      } catch (error) {
        console.error(error)
        if (isMounted) {
          setErrorMessage('Failed to fetch reservations.')
        }
      } finally {
        if (isMounted) {
          setShowLoader(false)
        }
      }
    }

    fetchReservations()

    return () => {
      isMounted = false
      unsubscribeReservations(hotelId)
    }
  }, [hotelId, dispatch, hotelInfo])

  useEffect(() => {
    const fetchEmailTimePeriod = async () => {
      try {
        const docRef = db.collection(Collections.HOTELS).doc(hotelId)
        const doc = await docRef.get()
        if (doc.exists) {
          const data = doc.data()
          console.log('Fetched email time period:', data.emailTimePeriod)
          setEmailTimePeriod(data.emailTimePeriod)
        }
      } catch (error) {
        console.error('Error fetching email time period:', error)
      }
    }

    fetchEmailTimePeriod()
  }, [hotelId])

  // useEffect(() => {
  //   console.log(reservations, 'reservations')
  //   const processPendingReservations = async () => {
  //     if (!emailTimePeriod) {
  //       console.log('No email time period configured')
  //       return
  //     }

  //     console.log('Processing reservations:', {
  //       count: reservations.length,
  //       emailTimePeriod,
  //     })

  //     for (const reservation of reservations) {
  //       if (reservation.emailAction === pendingLable) {
  //         console.log('Processing pending reservation:', {
  //           UniqueID: reservation.UniqueID,
  //           checkinDate: reservation.checkinDate,
  //         })
  //         await sendEmail(reservation)
  //       }
  //     }
  //   }

  //   if (reservations.length > 0) {
  //     processPendingReservations()
  //   }
  // }, [reservations, sendEmail, emailTimePeriod])

  const columns = [
    {
      title: 'Sl.No',
      dataIndex: 'index',
      width: 100,
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: translateTextI18N('Reservation Id'),
      dataIndex: 'reservationId',
      render: (text, row) => `${row.UniqueID}`,
      width: 150,
    },
    {
      title: translateTextI18N('Booking Reference'),
      dataIndex: 'bookingReference',
      render: (text, row) => `${row.bookingRefrence}`,
      width: 150,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      render: (text, row) => `${row.guestName}`,
      width: 200,
    },
    {
      title: translateTextI18N('Email'),
      dataIndex: 'email',
      render: (text, row) => `${row.email}`,
      width: 220,
    },
    {
      title: translateTextI18N('Arrival Date'),
      dataIndex: 'checkinDate',
      render: (text, row) => `${row.checkinDate}`,
      width: 150,
    },
    {
      title: translateTextI18N('Status'),
      dataIndex: 'status',
      render: (text, row) => `${row.status}`,
      width: 150,
    },
    {
      title: translateTextI18N('Action'),
      dataIndex: 'emailAction',
      width: 150,
      render: (_, row) => {
        const { text, disabled, showButton } = getEmailButtonProps(
          row.emailAction
        )
        return (
          <Space>
            {showButton && (
              <Button
                className={`statusBtn completedBtn`}
                onClick={() => handleResendEmail(row)}
              >
                {translateTextI18N(text)}
              </Button>
            )}
          </Space>
        )
      },
    },
  ]

  const handleConfirmationDialogOk = async () => {
    setShowConfirmationDialog(false)
    if (selectedReservation) {
      await sendEmail(selectedReservation)
    } else {
      try {
        const res = await sendEmailByEmailId(
          email,
          variables,
          emailTemp,
          hotelId
        )
        if (res.success) {
          setSuccessMessage(res.message)
        } else {
          setErrorMessage(res.message)
        }
      } catch (error) {
        console.error('Error sending email:', error)
        setErrorMessage('Failed to send email')
      }
    }
    setShowLoader(false)
  }

  const handleConfirmationDialogCancel = () => {
    setShowConfirmationDialog(false)
    setShowLoader(false)
    setSelectedReservation(null)
  }

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Reservations'
                breadcrumb={
                  hotelPmsIntergationType === 'Only Pms'
                    ? ['PMS', 'Reservations']
                    : ['Department Admin', 'Front Desk', 'PMS', 'Reservations']
                }
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <Select
                        id='reservationFilter'
                        options={reservationIds}
                        value={filters.reservationId || undefined}
                        onChange={value =>
                          setFilters({ ...filters, reservationId: value })
                        }
                        showSearch
                        placeholder='Reservation ID'
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <Select
                        id='bookingReferenceFilter'
                        options={bookingReferences}
                        value={filters.bookingReference || undefined}
                        onChange={value =>
                          setFilters({
                            ...filters,
                            bookingReference: value,
                          })
                        }
                        showSearch
                        placeholder='Booking Reference'
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <Select
                        id='guestNameFilter'
                        options={guestNames}
                        value={filters.guestName || undefined}
                        onChange={value =>
                          setFilters({ ...filters, guestName: value })
                        }
                        showSearch
                        placeholder='Guest Name'
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <DatePicker
                        format={dateFormatList}
                        placeholder={translateTextI18N('Arrival Date')}
                        value={filters.arrivalDate}
                        onChange={handleArrivalDateChange}
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-auto '>
                    <div className='cmnSelect-form'>
                      <Button
                        id='realIncoReset'
                        type='primary'
                        className='adduserbtn'
                        onClick={resetFilter}
                      >
                        <img
                          src={getImage('images/clearicon.svg')}
                          alt=''
                        ></img>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={!!successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={!!errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={filteredReservations}
                      loading={showLoader}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        onCancelClick={handleConfirmationDialogCancel}
        onOkClick={handleConfirmationDialogOk}
        title={`Send Pre-checkin Email`}
        message={`Are you sure you want to send pre-checkin email to the guest`}
        okButtonText='Confirm'
      />
    </>
  )
}

export default PMSreserve
