import React, { useState, useEffect } from 'react'
import { Button, Input, Form, Spin, Select, message } from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import { db } from '../../../../config/firebase'
import { Collections } from '../../../../config/constants'
import { useSelector } from 'react-redux'
import { callOperaCloudOAuthTokenAPI } from '../../../../services/pms'
import CronScheduler from './PmsCronScheduler'
import Axios from 'axios'
import { APIs } from '../../../../config/constants'
import PropTypes from 'prop-types'

// Constants
const TIME_OPTIONS = {
  month: {
    value: 30,
    label: '1 month before arrival',
    unit: 'days',
  },
  week: {
    value: 7,
    label: '1 week before arrival',
    unit: 'days',
  },
  day: {
    value: 1,
    label: '1 day before arrival',
    unit: 'days',
  },
  arrival: {
    value: 0,
    label: 'On arrival day',
    unit: 'days',
  },
  custom: {
    value: 'custom',
    label: 'Custom Timing',
    unit: 'days',
  },
}

const PmsConfiguration = () => {
  // State management
  const [formValues, setFormValues] = useState({
    userName: '',
    password: '',
    domain: '',
    emailTimePeriod: '',
    customTime: '',
    operaCloudClientSecret: '',
    pmsHotelId: '',
    operaCloudClientId: '',
    operaCloudHostName: '',
    operaCloudAppKey: '',
    operaCloudUsername: '',
    operaCloudPassword: '',
  })
  const [loading, setLoading] = useState(true)
  const [cronConfig, setCronConfig] = useState(null)
  const [currentData, setCurrentData] = useState(null)
  const [form] = Form.useForm()

  // Redux state
  const { hotelInfo } = useSelector(state => state)
  const hotelId = hotelInfo.hotelId
  const pmsType = hotelInfo?.pmsType
  const hotelPmsIntergationType = hotelInfo?.pmsIntergationType

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = db.collection(Collections.HOTELS).doc(hotelId)
        const doc = await docRef.get()

        if (doc.exists) {
          const data = doc.data()
          setCurrentData(data)
          const initialValues = {}

          // Set common PMS Hotel ID for all types
          initialValues.pmsHotelId =
            data.pmsHotelId || ''

          if (data.pmsType === 'OPERA CLOUD') {
            // Set Opera Cloud credentials
            initialValues.operaCloudClientSecret =
              data.operaCloudClientSecret || ''
            initialValues.operaCloudClientId = data.operaCloudClientId || ''
            initialValues.operaCloudHostName = data.operaCloudHostName || ''
            initialValues.operaCloudAppKey = data.operaCloudAppKey || ''
            initialValues.operaCloudUsername = data.operaCloudUsername || ''
            initialValues.operaCloudPassword = data.operaCloudPassword || ''
          } else {
            // Set regular PMS credentials
            initialValues.userName = data.pmsUserName || ''
            initialValues.password = data.pmsPassword || ''
            initialValues.domain = data.pmsDomain || ''
          }

          // Handle email time period for both types
          if (data.emailTimePeriod) {
            if (data.emailTimePeriod === 'custom') {
              initialValues.emailTimePeriod = 'custom'
              initialValues.customTime = data.customTime || ''
            } else {
              initialValues.emailTimePeriod = data.emailTimePeriod.days
            }
          }

          // Handle cron configuration
          if (data.cronType) {
            setCronConfig({
              cronType: data.cronType,
              minutesValue: data.minutesValue,
              hoursValue: data.hoursValue,
              scheduleTime: data.scheduleTime,
              selectedWeekdays: data.selectedWeekdays,
              selectedMonthDays: data.selectedMonthDays,
              cronExpression: data.cronInterval,
              cronDescription: data.cronDescription,
            })
          } else if (data.cronInterval) {
            setCronConfig(data.cronInterval)
          }

          setFormValues(initialValues)
          form.setFieldsValue(initialValues)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        message.error('Failed to load PMS configuration data')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [hotelId, form])

  // Helper functions
  const formatTimePeriod = (value, customTimeValue) => {
    if (!value && value !== 0) return null

    if (value === 'custom') {
      return {
        days: parseInt(customTimeValue),
        timestamp: Date.now(),
      }
    }

    return {
      days: parseInt(value),
      timestamp: Date.now(),
    }
  }

  const handleFormChange = changedValues => {
    setFormValues(prev => ({ ...prev, ...changedValues }))
  }

  const handleCronChange = config => {
    setCronConfig(config)
  }

  // Form submission handler
  const handleSubmit = async () => {
    try {
      setLoading(true)
      const docRef = db.collection(Collections.HOTELS).doc(hotelId)
      const formattedTimePeriod = formatTimePeriod(
        formValues.emailTimePeriod,
        formValues.customTime
      )

      const dataToSave = {
        pmsHotelId: formValues.pmsHotelId,
        emailTimePeriod: formattedTimePeriod,
        customTime:
          formValues.emailTimePeriod === 'custom'
            ? formValues.customTime
            : null,
        cronInterval: cronConfig?.cronExpression || '',
        cronType: cronConfig?.cronType,
        minutesValue: cronConfig?.minutesValue,
        hoursValue: cronConfig?.hoursValue,
        scheduleTime: cronConfig?.scheduleTime,
        selectedWeekdays: cronConfig?.selectedWeekdays,
        selectedMonthDays: cronConfig?.selectedMonthDays,
        cronDescription: cronConfig?.cronDescription,
      }

      // Add credentials based on PMS type
      if (pmsType === 'OPERA CLOUD') {
        Object.assign(dataToSave, {
          operaCloudClientSecret: formValues.operaCloudClientSecret,
          operaCloudClientId: formValues.operaCloudClientId,
          operaCloudHostName: formValues.operaCloudHostName,
          operaCloudAppKey: formValues.operaCloudAppKey,
          operaCloudUsername: formValues.operaCloudUsername,
          operaCloudPassword: formValues.operaCloudPassword,
        })
      } else {
        Object.assign(dataToSave, {
          pmsUserName: formValues.userName,
          pmsPassword: formValues.password,
          pmsDomain: formValues.domain,
        })
      }

      await docRef.set(dataToSave, { merge: true })

      // Update email schedule if cron configuration changed
      if (currentData?.cronInterval !== dataToSave.cronInterval) {
        const response = await Axios.post(APIs.UPDATE_EMAIL_SCHEDULE, {
          hotelId,
          cronInterval: dataToSave.cronInterval,
        })

        if (!response.data.success) {
          console.error(
            'Failed to update email schedule:',
            response.data.message
          )
        }
      }

      // Verify Opera Cloud credentials if applicable
      if (pmsType === 'OPERA CLOUD') {
        const authResponse = await callOperaCloudOAuthTokenAPI({
          operaCloudHotelId: formValues.pmsHotelId,
        })

        if (!authResponse.success) {
          throw new Error(
            authResponse.message || 'Opera Cloud authentication failed'
          )
        }
      }

      message.success('PMS configuration saved successfully')
    } catch (error) {
      console.error('Error saving PMS configuration:', error)
      message.error(error.message || 'Failed to save PMS configuration')
    } finally {
      setLoading(false)
    }
  }

  // Render appropriate form fields based on PMS type
  const renderPmsFields = () => {
    return (
      <>
        <Form.Item
          name='pmsHotelId'
          label='PMS Hotel ID'
          rules={[{ required: true, message: 'PMS Hotel ID is required' }]}
        >
          <Input placeholder='PMS Hotel ID' />
        </Form.Item>

        {pmsType === 'OPERA CLOUD' ? (
          <>
            <Form.Item
              name='operaCloudClientSecret'
              label='Client Secret'
              rules={[{ required: true, message: 'Client Secret is required' }]}
            >
              <Input.Password placeholder='Opera Cloud Client Secret' />
            </Form.Item>
            <Form.Item
              name='operaCloudClientId'
              label='Client ID'
              rules={[{ required: true, message: 'Client ID is required' }]}
            >
              <Input placeholder='Opera Cloud Client ID' />
            </Form.Item>
            <Form.Item
              name='operaCloudHostName'
              label='Host Name'
              rules={[{ required: true, message: 'Host Name is required' }]}
            >
              <Input placeholder='Opera Cloud Host Name' />
            </Form.Item>
            <Form.Item
              name='operaCloudAppKey'
              label='App Key'
              rules={[{ required: true, message: 'App Key is required' }]}
            >
              <Input placeholder='Opera Cloud App Key' />
            </Form.Item>
            <Form.Item
              name='operaCloudUsername'
              label='Username'
              rules={[{ required: true, message: 'Username is required' }]}
            >
              <Input placeholder='Opera Cloud Username' />
            </Form.Item>
            <Form.Item
              name='operaCloudPassword'
              label='Password'
              rules={[{ required: true, message: 'Password is required' }]}
            >
              <Input.Password placeholder='Opera Cloud Password' />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name='userName'
              label='Username'
              rules={[{ required: true, message: 'Username is required' }]}
            >
              <Input placeholder='PMS Username' />
            </Form.Item>
            <Form.Item
              name='password'
              label='Password'
              rules={[{ required: true, message: 'Password is required' }]}
            >
              <Input.Password placeholder='PMS Password' />
            </Form.Item>
            <Form.Item
              name='domain'
              label='Domain'
              rules={[{ required: true, message: 'Domain is required' }]}
            >
              <Input placeholder='PMS Domain' />
            </Form.Item>
          </>
        )}
      </>
    )
  }

  // Main render
  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='PMS Configuration'
                breadcrumb={
                  hotelPmsIntergationType === 'Only Pms'
                    ? ['PMS', 'PMS Configuration']
                    : ['Department Admin', 'PMS', 'PMS Configuration']
                }
              />
            </div>
            <div className='col-12 col-xl-12'>
              {loading ? (
                <div className='row ml-2 mb-2 justify-content-center'>
                  <Spin tip='Loading...' />
                </div>
              ) : (
                <div className='row ml-2 mb-2 justify-content-start'>
                  <div className='col-auto'>
                    <Form
                      form={form}
                      layout='vertical'
                      onFinish={handleSubmit}
                      onValuesChange={handleFormChange}
                      initialValues={formValues}
                    >
                      {renderPmsFields()}

                      {/* Cron Schedule Builder */}
                      <Form.Item label='Auto Reservation Refresh Cron' required>
                        <CronScheduler
                          value={cronConfig}
                          onChange={handleCronChange}
                        />
                      </Form.Item>

                      <Form.Item
                        name='emailTimePeriod'
                        label='Pre-Check-In Automatic Email Dispatch Schedule'
                        rules={[
                          {
                            required: true,
                            message: 'Email schedule is required',
                          },
                        ]}
                      >
                        <Select placeholder='Select Schedule'>
                          {Object.entries(TIME_OPTIONS).map(([key, option]) => (
                            <Select.Option key={key} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {formValues.emailTimePeriod === 'custom' && (
                        <Form.Item
                          name='customTime'
                          label='Custom Timing (in days)'
                          rules={[
                            {
                              required: true,
                              message: 'Custom time is required',
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: 'Please enter a valid number of days',
                            },
                          ]}
                        >
                          <Input
                            placeholder='e.g., 5 days before arrival'
                            addonAfter='days before arrival'
                          />
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          type='primary'
                          htmlType='submit'
                          loading={loading}
                        >
                          Save Configuration
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

PmsConfiguration.propTypes = {
  hotelInfo: PropTypes.shape({
    hotelId: PropTypes.string.isRequired,
    pmsType: PropTypes.string,
    pmsIntergationType: PropTypes.string,
  }),
}

export default PmsConfiguration
